* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  background: -webkit-linear-gradient(
    top,
    rgb(0, 0, 0) 50%,
    rgb(25, 19, 39) 90%,
    rgb(43, 32, 72)
  );
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.button {
  /* padding: 8px 20px;
  border: 1px solid red;
  background-color: blue;
  color: white;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer; */
  white-space: nowrap;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 10px 20px;
  border: 1px solid transparent;
  color: #fff;
  border-radius: 14px;
  background-color: transparent;
  cursor: pointer;
  flex: 1;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  box-shadow: 0 0px 4px rgba(255, 255, 255, 0.425);
  scale: 1;
  opacity: 1;
  transition: all 0.2s ease-out;
}

.button svg {
  fill: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.open-button {
  display: inline-block;
  background-color: white;
  border-color: white;
  color: rgb(43, 32, 72);
  flex: 0;
  text-align: center;
  /* margin-left: auto; */
}

.close-button {
  display: inline-block;
  padding: 20px;
  flex: 0;
}

html,
body {
  min-height: 100svh;
}

body {
  display: flex;
  flex-direction: column;
  position: relative;
}

#root {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

body canvas {
  object-fit: cover;
  object-position: center;
}

.form {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  gap: 10px;
  width: 100%;
  /* max-width: 240px; */
}

.form label {
  color: #fff;
  font-size: 14px;
}

.form input {
  padding: 10px;
  border-radius: 10px;
  border: 0px;
  background-color: rgb(43, 32, 72);
  color: #fff;
  box-shadow: 0 0px 4px rgba(255, 255, 255, 0.425);
  outline: none;
  border: 1px solid #fff;
  padding-right: 50px;
}

.form input::placeholder {
  color: rgba(255, 255, 255, 0.825);
}

.fit-button {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.ReactModal__Content.ReactModal__Content--after-open {
  width: 500px;
  max-width: 95%;
  container-name: sidebar;
  container-type: inline-size;
}

@container (width < 350px) {
  .flexContainer {
    flex-direction: column;
  }

  .flexContainer img {
    max-width: 100px !important;
  }
}

.dropdown {
  position: absolute;
  right: 0;
  top: calc(100% + 10px);
  padding: 10px;
  padding-right: 5px;
  width: 100%;
  border: 1px solid white;
  background: rgb(43, 32, 72);
  border-radius: 14px;
  max-height: calc(100vh - 77px - 60px);
  display: flex;
  /* overflow-x: hidden; */
}

.dropdown ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;

  overflow-x: hidden;
  padding: 1px;
  padding-right: 5px;
}

.dropdown .button {
  width: 100%;
  padding: 5px 10px;
  border-radius: 10px;
  align-items: center;
  white-space: normal;
  text-align: left;
}

.highlight {
  background-color: yellowgreen;
}

.clear {
  position: absolute;
  right: 0px;
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: 1px solid white;
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 10px;
  height: 38px;
  top: 50%;
  transform: translateY(calc(-50%));
  line-height: 1;
  cursor: pointer;
  color: #fff;
}

.clear:hover {
  background-color: #ffffff25;
}

.modal_overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.2);
}

.functionl {
  background-color: rgba(255, 255, 255, 0.2);
}
